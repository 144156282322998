<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full font-sans">
        <vue-good-table
          ref="deliveryDocumentTable"
          styleClass="vgt-table striped bordered condensed whitespace-nowrap"
          row-style-class="text-sm"
          :columns="columns"
          :rows="alarms"
          :search-options="{
            enabled: false,
            placeholder: 'Sök',
          }"
          :pagination-options="{
            enabled: true,
            position: 'top',
            mode: 'pages',
            jumpFirstOrLast: true,
            perPage: 1000,
            perPageDropdown: [500, 1000, 1500],
            dropdownAllowAll: false,
            pageLabel: 'page',
            rowsPerPageLabel: 'per page',
          }"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: serverParams.sort,
          }"
          :line-numbers="true"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
        >
          <template slot="column-filter" slot-scope="{ column, updateFilters }">
            <div class="inline-block w-80 border-separate whitespace-pre-line" v-if="column.filterOptions && column.filterOptions.customFilter">
              <div class="text-xs font-normal font-sans" v-if="column.field == 'parent_id'">
                <treeselect v-model="selectedUnits" :options="unitOptions" @close="updateFilters(column, selectedUnits)" :beforeClearAll="clearAllUnits" :multiple="true" value-consists-of="LEAF_PRIORITY" noChildrenText="Ingen verksamhet" />
              </div>
            </div>
          </template>
          <template slot="loadingContent">
            <div class="text-center text-2xl text-primary-500"><BaseIcon icon="spinner" spin /></div>
          </template>
          <div slot="table-actions">
            <span class="text-xs mr-8 text-gray-500">{{ $t("showing") }} {{ startRow }} to {{ endRow }} of {{ totalRecords }}</span>
            <button class="btn-secondary-outline mx-1" @click="resetTable()">
              {{ $t("reset") }}
              <BaseIcon icon="redo" class="ml-1" />
            </button>
            <button class="btn-blue mx-1" @click="exportAlarms()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'parent_id'">{{ props.row.customer_unit_name }}</span>
            <span v-else-if="props.column.field == 'alarm_central'">{{ props.row.alarm_central_name }}</span>
            <span v-else-if="props.column.field == 'seller'">{{ props.row.seller_name }}</span>
            <span v-else-if="props.column.field == '_id'">
              {{ getSecurCloudName(props.row.gps_active, props.row.gps_log_active) }}
            </span>
            <span v-else-if="props.column.field == 'unit_name'">
              <span v-if="props.row.gps_last_seen" class="text-xxs last-seen mr-1" v-bind:class="{ inactive: new Date().getTime() - new Date(props.row.gps_last_seen).getTime() > 1000 * 60 * 60 }">⬤</span>
              <span v-else class="mr-5"></span>
              <router-link target="_blank" class="font-sans text-primary-500 hover:text-primary-300" :to="'/larm/customer/' + getCustomerId(props.row.parent_id) + '/' + props.row.parent_id + '/-/' + props.row._id">
                {{ props.row.unit_name }}
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  title() {
    return `Utleveransdokument | SecurCloud`;
  },
  components: {
    Treeselect,
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      alarms: [],
      customers: [],
      units: [],
      sellers: [],
      alarm_centers: [],
      serverParams: {
        filters: {},
        sort: [{ field: "contract_start", type: "asc" }],
        page: 1,
        perPage: 1000,
      },
      unitOptions: [],
      selectedUnits: [],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    startRow() {
      return (this.serverParams.page - 1) * this.serverParams.perPage + (this.alarms.length > 0 ? 1 : 0);
    },
    endRow() {
      return (this.serverParams.page - 1) * this.serverParams.perPage + this.alarms.length;
    },
    columns() {
      let ajaxDevices = ["AJAX Hub 2 Plus", "AJAX Hub 2 (4G)", "AJAX Hub 2 (2G)", "AJAX Hub (4G)", "AJAX Hub (2G)", "AJAX DoorProtect Plus", "AJAX DoorProtect", "AJAX ReX 2", "AJAX ReX", "AJAX MotionProtect Plus", "AJAX MotionProtect", "AJAX CombiProtect", "AJAX MotionCam", "AJAX MotionCam (PhOD)", "AJAX MotionProtect Curtain", "AJAX MotionCam Outdoor", "AJAX MotionCam Outdoor (PhOD)", "AJAX MotionProtect Outdoor", "AJAX DualCurtain Outdoor", "AJAX KeyPad TouchScreen", "AJAX KeyPad Plus", "AJAX KeyPad", "AJAX Button", "AJAX DoubleButton", "AJAX SpaceControl"];
      let personalAlarms = ["SRT306", "SRT306i", "SRT306 G2", "SRT326", "SRT330", "SRT334", "SRT405", "SRT405i", "SRT406", "SRT406i", "SRT430", "SRT278", "SRT341", "TWIG Neo", "TWIG Neo SRD", "TWIG Neo MD", "TWIG Neo SRD MD", "TWIG One", "TWIG One SRD", "TWIG One EX", "TWIG Beacon", "TWIG Beacon EX", "EMERIT WG100", "TELTONIKA FMC003", "4G Box", "Anchor Beacon", "Avigilon Alta Compact Dome 5MP"];
      let devices = [...personalAlarms, ...ajaxDevices];

      return [
        { label: this.$t("deliveries_table.start_date"), field: "contract_start", formatFn: this.formatDate, sortable: true },
        { label: this.$t("deliveries_table.customer_name"), field: "customer_name" },
        { label: this.$t("deliveries_table.business_name"), field: "parent_id", filterOptions: { customFilter: true } },
        { label: this.$t("deliveries_table.name_of_Product"), field: "unit_name" },
        { label: this.$t("deliveries_table.telematics_number"), field: "phone" },
        { label: this.$t("deliveries_table.type_of_device"), field: "type", filterOptions: { enabled: true, placeholder: "Typ av enhet", filterDropdownItems: devices } },
        { label: this.$t("deliveries_table.agreement_number"), field: "agreement_number", sortable: true },
        { label: this.$t("deliveries_table.contract_length"), field: "agreement_length", sortable: true },
        { label: this.$t("deliveries_table.contract_renewal"), field: "agreement_end", formatFn: this.formatDate, sortable: true },
        { label: this.$t("deliveries_table.seller"), field: "seller", filterOptions: { enabled: true, placeholder: "Säljare", filterDropdownItems: [] } },
        { label: this.$t("deliveries_table.alarm_center"), field: "alarm_central", filterOptions: { enabled: true, placeholder: "Larmcentral", filterDropdownItems: [] } },
        { label: this.$t("deliveries_table.SIM_card"), field: "operator", filterOptions: { enabled: true, placeholder: "Simkort", filterDropdownItems: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"] } },
        {
          label: "SercurCloud",
          field: "_id",
          // used _id for easy filtering SecurCloud status, remove if _id is used for other purpose
          // filterOptions: { enabled: true, placeholder: 'SercurCloud', filterDropdownItems: ['FULL', 'BAS'] }
        },
        {
          label: "9-Vibb",
          field: "vibration_alert",
          formatFn: this.getVibrationAlertName,
          // filterOptions: { enabled: true, placeholder: '9-Vibb',
          // filterDropdownItems: ['9-vibb Push B', '9-vibb Call B', '9-vibb Båda'] }
        },
        { label: this.$t("deliveries_table.serial_number"), field: "modelnumber" },
        { label: "IMEI", field: "imei_number" },
      ];
    },
  },

  methods: {
    getDeliveryDocumentAlarm() {
      this.axios
        .post(`${process.env.VUE_APP_SERVER_URL}/delivery-document/alarm`, this.serverParams)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.alarms = response.data[0].results;

            let count = 0;
            if (response.data[0].pageInfo.length > 0) count = response.data[0].pageInfo[0].count;
            this.totalRecords = count;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getListCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/customer`)
        .then((response) => {
          this.customers = response.data.customers;
          this.units = response.data.units;
          this.unitOptions = response.data.unitOptions;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getListAlarmCenter() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/alarm-center`)
        .then((response) => {
          this.alarm_centers = response.data.alarm_centers;
          let alarmcenter_names = _.map(this.alarm_centers, function(o) {
            return { value: o._id, text: o.name };
          });
          let alarmcenter_col = _.find(this.columns, { field: "alarm_central" });
          alarmcenter_col.filterOptions.filterDropdownItems = alarmcenter_names;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getListSeller() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/seller`)
        .then((response) => {
          this.sellers = response.data.sellers;
          let seller_names = _.map(this.sellers, function(o) {
            return { value: o._id, text: o.name };
          });
          let seller_col = _.find(this.columns, { field: "seller" });
          seller_col.filterOptions.filterDropdownItems = seller_names;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    exportAlarms() {
      if (this.$refs["deliveryDocumentTable"].filteredRows && this.$refs["deliveryDocumentTable"].filteredRows.length > 0) {
        const newColumns = this.$refs["deliveryDocumentTable"].filteredRows[0].children.map((item) => ({
          Startdatum: this.formatDate(item.contract_start),
          Kundnamn: item.customer_name,
          Verksamhetnamn: item.customer_unit_name,
          "Namn på Produkt": item.unit_name,
          Telematiknumer: item.phone,
          Serienummer: item.modelnumber,
          IMEI: item.imei_number,
          "Typ av enhet": item.type,
          Avtalsnummer: item.agreement_number,
          Avtalslängd: item.agreement_length,
          SercurCloud: this.getSecurCloudName(item.gps_active, item.gps_log_active),
          Larmcentral: item.alarm_central_name,
          "9-Vibb": this.getVibrationAlertName(item.vibration_alert),
          Säljare: item.seller_name,
          Avtalsförnyelse: this.formatDate(item.agreement_end),
          Simkort: item.operator,
        }));

        this.excelExport(newColumns, "Utleveransdokument", "Utleveransdokument");
      } else this.handleError(null, "Ingen data att exportera", "top-right", 3000);
    },

    getSecurCloudName(gps_active, gps_log_active) {
      return gps_active || gps_log_active ? "FULL" : "BAS";
    },

    getVibrationAlertName(vibration_alert) {
      let vibration_alert_name = "";
      if (vibration_alert == "vib_pushb") vibration_alert_name = "9-vibb Push B";
      else if (vibration_alert == "vib_callb") vibration_alert_name = "9-vibb Call B";
      else if (vibration_alert == "vib_both") vibration_alert_name = "9-vibb Båda";

      return vibration_alert_name;
    },

    formatDate(date) {
      if (date) return this.moment(date).format("YYYY-MM-DD");
      else return "-";
    },

    getCustomerId(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) {
        let c = _.find(this.customers, { _id: u.parent_id });
        if (c) return c.customer_id;
        else return "-";
      } else return "-";
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getDeliveryDocumentAlarm();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.getDeliveryDocumentAlarm();
    },

    onSortChange(params) {
      this.updateParams({ sort: params });
      this.getDeliveryDocumentAlarm();
    },

    onColumnFilter(params) {
      let filters = params.columnFilters;

      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (filters[key] == "") delete filters[key];
        }
      }

      this.updateParams({ filters, page: 1 });
      this.getDeliveryDocumentAlarm();
    },

    clearAllUnits() {
      if (this.serverParams.filters && this.serverParams.filters.parent_id) delete this.serverParams.filters.parent_id;
      this.getDeliveryDocumentAlarm();

      return true;
    },

    resetTable() {
      this.selectedUnits = [];
      this.serverParams.filters = {};
      this.serverParams.sort = { field: "contract_start", type: "asc" };
      this.serverParams.page = 1;
      this.$refs["deliveryDocumentTable"].globalSearchTerm = "";
      this.$refs["deliveryDocumentTable"].reset();
    },
  },

  created() {
    this.getListCustomer();
    this.getListAlarmCenter();
    this.getListSeller();

    this.setPageTitle(`Utleveransdokument`, "alarm_admin");
  },
};
</script>
